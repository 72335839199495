<template>
  <PageWithLayout>
    <p class="desc_info">💡 기 등록된 배너중에 최종게시할 배너를 선택해 주세요. 총 40개까지 등록가능합니다. 지정 후 저장버튼을 눌러야 반영됩니다. </p>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData">
    </Board>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="되돌리기"
          :disabled="viewModel.isDisabledResetBtn"
          @onClickBtn="viewModel.onClickReset()">
          <IconSvg
            iconName="reset"
            :size="12"
            :iconColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON' : '#222'"
            :iconDarkColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON_DARK' : '#fff'"/>
      </Button>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickCompleteFaqTopTen()"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <BannerSourceSearchPopup
        v-if="viewModel.isBannerSourceSearchPopup"
        @onClickClose="viewModel.onClickCloseBannerSourceSearchPopup()"
        @onClickComplete="data => viewModel.onClickCompleteBannerSourceSearchPopup(data)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// popup
import BannerSourceSearchPopup from '@/views/app/banner/view/popup/BannerSourceSearchPopup';
// viewModel
import BannerPostingViewModel from '@/views/app/banner/viewModel/BannerPostingViewModel'

export default {
  name:'BannerPosting',
  components:{
    PageWithLayout,
    Board,
    Sticky,
    Button,
    IconSvg,
    BannerSourceSearchPopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new BannerPostingViewModel(),
    }
  },
}
</script>

<style scoped>
.desc_info + .board_comm{margin-top:20px}

.board_comm::v-deep .fold .tit_question{display:block;padding-bottom:9px;line-height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.board_comm::v-deep .fold .txt_tbl{display:block;overflow:hidden;height:29px;margin:9px 0 0;line-height:22px;}
.board_comm::v-deep .tr_fold .txt_tbl{height:auto;margin-bottom:9px;overflow:inherit;white-space:inherit;text-overflow:inherit}
.board_comm::v-deep .tr_fold .tit_question{overflow:visible;white-space:inherit;text-overflow:inherit;color:#222}
.board_comm::v-deep .tr_fold .editor_view p{min-height:22px;font-size:14px;line-height:22px;color:#666}
/* .board_comm::v-deep .icon_firfin{margin-top:10px}
.board_comm::v-deep .tr_fold .icon_firfin{transform:scaleY(-1)} */
@media (prefers-color-scheme: dark) {
  .board_comm::v-deep .tr_fold .tit_question{color:#fff}
}
/* 모바일 */
@media all and (max-width:1240px){
  .board_comm::v-deep .fold .txt_tbl{margin:0;padding:0}
}
</style>