
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class BannerPostingViewModel {
  constructor() {
    this.boardData = {
      drawDataList: [
        {
          title:'순서',
          width:'58px',
          icon:{
            iconName:'drag',
            size:14,
            style:{margin:'12px'}
          },
        },
        {
          title:'No.',
          width:'50px',
          value:'rowIndex',
        },
        {
          title:'베너섬네일',
          width:'138px',
          value:'bannerImageUrl',
          img:{
            size:'contain',
            width:'126px',
            height:'40px',
            position:{
              x:'center',
              y:'center',
            }
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'누적클릭수',
          width:'76px',
          value:'viewCount',
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'타겟구분',
          width:'94px',
          value:'target',
          filter: {
            name:'convertIdToText',
            value:'user_target'
          },
          class: {
            classColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'user_target',
              prev: 'tc',
            },
          },
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'게시기간',
          width:'110px',
          value:'',
          dateRangeValue:{
            startValue: 'startAt',
            endValue: 'endAt',
            betweenText: ' ~ ',
            isEndPastDateToRed: true
          },
          isAlignLeft: true
        },
        {
          title:'내리기',
          width:'74px',
          value:'isEmpty',
          buttonCondition: [
            {
              condition:false,
              text:'내리기',
              size:'small',
              style:'fourthly_border',
              disabled:false,
              onClickEvent: (data,rowIndex) => this.onClickDelete(data,rowIndex)
            },
          ],
        },
        {
          title:'올리기',
          width:'88px',
          value:'isEmpty',
          buttonCondition: [
            {
              condition:false,
              text:'변경',
              size:'small',
              style:'secondary_border',
              disabled:false,
              icon:{
                iconName:'reset',
                size:10,
                iconColor:'#222'
              },
              onClickEvent: (data,rowIndex) => this.onClickEdit(data,rowIndex)
            },
            {
              condition:true,
              text:'추가',
              size:'small',
              style:'secondary',
              disabled:false,
              icon:{
                iconName:'add',
                size:10,
                iconColor:'#fff',
              },
              onClickEvent: (data,rowIndex) => this.onClickEdit(data,rowIndex)
            },
          ],
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        isDrag: true,
        dragHandle:'.icon_drag',
        onEndDrag:(e,data) => this.onEndDrag(e,data)
      }
    };
    this.totalBannerPostingCount = 40;
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:40,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isDisabledResetBtn = true;
    this.isBannerSourceSearchPopup = false;
    this.editIndex = null
  }
  init(){
    this.getData();
  }
  onClickReset(){
    this.isDisabledResetBtn = true;
    this.getData();
  }
  onEndDrag(e){
    if( e.newIndex === e.oldIndex ) return;
    this.isDisabledResetBtn = false;
    this.dataList = this.dataList.map((item,index) => {
      let rObj = item;
      if(item.isEmpty){
        rObj.id = `new${index+1}`
      }
      return rObj;
    })
  }
  onClickDelete(data,rowIndex){
    let empty = {
      id : `new${rowIndex + 1}`,
      title : '',
      viewCount : '',
      bannerImageUrl : '',
      startAt : '',
      endAt : '',
      isEmpty: true
    }
    const newDataList = JSON.parse(JSON.stringify(this.dataList));
    newDataList[rowIndex] = empty;
    this.dataList = newDataList;
  }
  onClickEdit(data,rowIndex){
    this.editIndex = rowIndex;
    this.isBannerSourceSearchPopup = true;
  }
  onClickCloseBannerSourceSearchPopup(){
    this.editIndex = null;
    this.isBannerSourceSearchPopup = false;
  }
  onClickCompleteBannerSourceSearchPopup(data){
    if(this.dataList.find(item => item.bannerContentsId === data.id)){
      store.dispatch('commonToast/fetchToastStart', `이미 게시된 베너입니다`);
      return;
    }
    this.isBannerSourceSearchPopup = false;
    data.isEmpty = false;
    let dataListSync = [];
    dataListSync = dataListSync.concat(this.dataList);
    dataListSync[this.editIndex] = data;
    this.dataList = dataListSync;
    this.editIndex = null;
  }

  onClickCompleteFaqTopTen(){
    this.postData()
  }
  getData(){
    let resultData = [];
    const path = `${apiPath.BANNER_POSTING_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      resultData = success.data;
      this.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
      this.setData(resultData)
    })
  }
  setData(resultData){
    resultData = resultData.map(item => {
      item.isEmpty = false;
      return item
    })
    if(resultData.length < this.totalBannerPostingCount){
      const addDataLength = this.totalBannerPostingCount - resultData.length;
      let newIndex = resultData.length +1;
      for(var i = 0; i < addDataLength; i++){
        let newData = {
          id : `new${newIndex}`,
          title : '',
          viewCount : '',
          bannerImageUrl : '',
          startAt : '',
          endAt : '',
          isEmpty: true
        }
        resultData.push(newData)
        newIndex += 1;
      }
    }
    this.dataList = resultData
    this.paginationData.totalCount = this.totalBannerPostingCount;
    this.paginationData.totalPage = 1;
  }
  postData(){
    const path = `${apiPath.BANNER_POSTING_POSITION}`;
    const data = {
      positions : this.dataList.filter(item => !item.isEmpty).map((item,index) => {
        let rObj = {
          id: item.bannerContentsId,
          sequence : index +1
        };
        return rObj;
      })
    };
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `베너게시가 저장되었습니다.`);
      this.getData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}