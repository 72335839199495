<template>
  <Popup
    title="베너소스검색"
    :maxWidth="800"
    closeBtnText="닫기"
    compeleteBtnText="선택완료"
    :isDisabeldCompeleteBtn="viewModel.selectedDataList.length === 0"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',viewModel.selectedDataList[0])">
    <Search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :selectedDataList="viewModel.selectedDataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.isSearched ? '검색결과가 없습니다' : '베너소스를 검색해주세요' "
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
    </Board>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import BannerSourceSearchPopupViewModel from '@/views/app/banner/viewModel/BannerSourceSearchPopupViewModel'

export default {
  name:'BannerSourceSearchPopup',
  components:{
    Popup,
    Search,
    Board,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new BannerSourceSearchPopupViewModel(),
    }
  },
}
</script>
<style scoped>
</style>