import router from '@/router';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { setDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class BannerSourceSearchPopupViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'',
        type: 'Input',
        dataList:[],
        isFull:true,
        value:'',
      },
    ];
    this.isSearched = false;
    this.boardData = {
      title:'베너소스 검색결과',
      drawDataList: [
        {
          title:'베너섬네일',
          width:'120px',
          value:'bannerImageUrl',
          img:{
            size:'contain',
            width:'105px',
            height:'55px',
            position:{
              x:'center',
              y:'center',
            }
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isAlignLeft: true,
          emptyValueText:'⚠️',
          filter: {
            name:'convertLineFeed'
          },
        },
        {
          title:'타겟구분',
          width:'94px',
          value:'target',
          filter: {
            name:'convertIdToText',
            value:'user_target'
          },
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'게시기간',
          width:'194px',
          value:'',
          valueCustom:[
            {
              type: 'value',
              value:'startAt',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              },
            },
            { 
              type: 'text',
              value: ' ~ '
            },
            {
              type: 'value',
              value:'endAt',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              },
            },
          ],
          isEllip: true,
          isAlignLeft: true
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      },
      markData:[
        {
          id : 'title',
          value : this.searchDataList[0].value
        }
      ]
    };
    this.dataList = [];
    this.foldedDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.selectedDataList = [];
  }
  init(){
    // this.getData();
  }
  onSearch(){
    if(this.searchDataList[0].value){
      this.getData();
    }else{
      this.isSearched = false;
      store.dispatch('commonToast/fetchToastStart', '검색어를 입력해주세요');
    }
  }
  onClickRow(rowId,rowData){
    if(this.selectedDataList.length === 0){
      this.selectedDataList.push(rowData)
    }else if(this.selectedDataList[0].id != rowData.id){
      this.selectedDataList = [];
      this.selectedDataList.push(rowData)
    }else{
      this.selectedDataList = [];
    }
    // const isFolded = this.foldedDataList.length > 0 && this.foldedDataList.find(item => item === rowId);
    // if(isFolded){
    //   this.foldedDataList = this.foldedDataList.filter(item => item != rowId);
    // }else{
    //   this.foldedDataList.push(rowId);
    // }
  }
  onClickEdit(data){
    router.push({ name:'SERVICE_FAQ_MODIFY', params: { id : data.id } })
  }
  getData(){
    const query = makeQueryStringByObject(this.searchParams);
    const value = this.searchDataList[0].value
    const path = `${apiPath.BANNER_SOURCE_SEARCH.format(value)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.markData[0].value = value;
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
      this.isSearched = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
      this.isSearched = false;
    })
  }
}